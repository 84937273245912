import React from "react";
// import Link from "gatsby";
// <Link to="/">visit the homepage</Link>

/**
 * 404 Page
 */
export default function PageNotFound() {
  return (
    <>
      <h1>404: Sorry, the page you tried to visit doesn’t exist.</h1>
      <p>
        Please <a href="/">visit the homepage</a>.
      </p>
    </>
  );
}
